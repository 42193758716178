






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import tipos_campos_personalizadosModule from "@/store/modules/tipos_campos_personalizados-module.ts";
import campos_personalizadoModule from "@/store/modules/campos_personalizado-module.ts";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { campos_personalizado } from "@/shared/dtos/campos_personalizado";
@Component({
  components: {
    ConfiguracionFichaOpciones: () =>
      import("@/views/configuracion/configuracion-ficha-opciones.vue"),
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class ConfiguracionMedicionesAvanzadas extends Vue {
  public modeltab!: number;
  public check_activado: boolean[] = [];
  public dialog: boolean = false;
  public activa_check: boolean = false;
  public index_selected: number = 0;
  public async created() {
    await tipos_campos_personalizadosModule.gettipos_campos_personalizadoses();
    for (
      let i = 0;
      i <
      tipos_campos_personalizadosModule.tipos_campos_personalizadoses.length;
      i++
    ) {
      const modelo =
        tipos_campos_personalizadosModule.tipos_campos_personalizadoses[i];
      await campos_personalizadoModule.getTipoActivado(modelo.id);
      this.check_activado.push(campos_personalizadoModule.tipo_activado);
    }
    if (this.check_activado[0]) {
      campos_personalizadoModule.getcampos_personalizado_por_tipo(1);
    }
  }
  public get tabsnames() {
    return tipos_campos_personalizadosModule.tipos_campos_personalizadoses;
  }
  public cargar_mediciones(id: number) {
    this.index_selected = id - 1;
    if (this.check_activado[id - 1]) {
      campos_personalizadoModule.getcampos_personalizado_por_tipo(id);
    } else {
      campos_personalizadoModule.onGetcampos_personalizados([]);
    }
  }
  public get mediciones(): any[] {
    return campos_personalizadoModule.campos_personalizados;
  }
  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "formato_medicion",
        "Formato medición",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "visible_en_estadicticas",
        "Visible en estadísticas",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
  public activar_mediciones(mediciones: boolean, index: number) {
    this.activa_check = mediciones;
    this.dialog = true;
  }

  public Cancelarmediciones() {
    this.check_activado[this.index_selected] = !this.check_activado[
      this.index_selected
    ];
    this.dialog = false;
  }

  public Aceptarmediciones() {
    if (this.check_activado[this.index_selected]) {
      campos_personalizadoModule.crearCamposParaEltipoIId_nutri(
        this.tabsnames[this.index_selected].id
      );
    } else {
      campos_personalizadoModule.eliminarCamposParaEltipoIId_nutri(
        this.tabsnames[this.index_selected].id
      );
    }
    this.dialog = false;
  }

  public async Eliminar(rw: any) {
    await campos_personalizadoModule.eliminarcampos_personalizado(rw.key);
    await campos_personalizadoModule.getcampos_personalizado_por_tipo(
      this.tabsnames[this.index_selected].id
    );
  }

  public async RowInserted(e: any) {
    var insert = new campos_personalizado(e.data);
    insert.id = 0;
    insert.id_tipos_campos_personalizados = this.tabsnames[
      this.index_selected
    ].id;

    await campos_personalizadoModule.guardarcampos_personalizado(insert);
    await campos_personalizadoModule.getcampos_personalizado_por_tipo(
      insert.id_tipos_campos_personalizados
    );
  }
  public RowEdit(data_column: any) {
    campos_personalizadoModule.modificarcampos_personalizado(
      new campos_personalizado(data_column.data)
    );
  }
}
